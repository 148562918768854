<svg xmlns="http://www.w3.org/2000/svg" (click)="back()" cursor="pointer" font-weight="900" width="40" height="40" fill="black" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>
<div class="container">
  <div class="card">
    <article class="card-body">
      <form class='groupForm' name="GroupForm" [formGroup]="GroupForm">
        <div class="form-group">
          <label for="date">
            Group Name 
            <span class="required"> * </span>
          </label>
          <input id='input' formControlName='GroupName' type="text" class="form-control" placeholder="Enter GroupName">
          <div class="alert  alert-danger"
            *ngIf="!GroupForm.get('GroupName').valid && GroupForm.get('GroupName').touched">
            GroupName is required
          </div>
        </div>
        <div class="form-group">
          <label for="date">
             Group Description
            <span class="required"> * </span>
          </label>
          <input id='input' formControlName='Description' type="text" class="form-control"
            placeholder="Enter Group Description Optional">
          <select class="form-control" name="users" #select (change)='onOptionsSelected(select.value)'>
            <option id='input' *ngFor="let users of userName" placeholder='Add Users' ngValue="{{userName}}">
              {{users.userName}}</option>
          </select>
        </div>

        <div *ngFor="let ppl of membersIngGroup; let i = index">
          <p class='userName'> {{membersIngGroup[i]}} <button type="button" class="btn btn-danger"
              (click)="removeGroupMembers(membersIngGroup, i)">&times;</button></p>
        </div>                                  
        <div class="text-center">
          <button type="submit" [disabled]="!GroupForm.valid" class="btn btn-primary center-block ng-tns-c47-0"
            (click)='submit(GroupForm,membersIngGroup)'>Create Group!</button>
        </div>
      </form>
      </article>
      </div>  
  </div>
