<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->
<link rel="dns-prefetch" href="https://fonts.gstatic.com">
<link href="https://fonts.googleapis.com/css?family=Raleway:300,400,600" rel="stylesheet" type="text/css">



<link rel="icon" href="Favicon.png">

<!-- Bootstrap CSS -->
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css">


<div class="wrapper fadeInDown">
  <div class="card text-center">
    <div class="card-body">
      <h5 class="webTitle">House Chores </h5>
      <p class="card-text">A step closer to a tidier households</p>
    </div>
  </div>
  <div id="formContent">
    <!-- Tabs Titles -->

    <!-- Icon -->
    <div class="fadeIn first">
    </div>

    <!-- Login Form -->
    <form name="loginForm" [formGroup]="loginForm">
      <input formControlName="email" type="text" id="login" class="form-control" class="fadeIn second" name="login"
        placeholder="email">
      <input formControlName='password' class="form-control" placeholder="password" class="fadeIn third"
        [type]="show ? 'text' : 'password'" name="login">
      <input [disabled]="!loginForm.valid" (click)="signIn(loginForm)" type="submit" id="logButton"
        class="fadeIn fourth" value="Log In">
      <br>
      <button type="button" (click)="passwordDisplay()" class="btn btn-outline-primary">Show Password</button>
      <br>
    </form>

    <!-- Remind Passowrd -->
    <div id="formFooter">
      <a (click)="signUp()" class="underlineHover">Register</a>
    </div>

  </div>
</div>



<div *ngIf="needsToSignUp" class="register">
  <app-register></app-register>


</div>
