<div class="container justify-content-center mt-5 border-left border-right">

  <div class="d-flex justify-content-center pt-3 pb-2"> 
      <input  [(ngModel)] ="comment" type="text" name="text" placeholder="+ Add a note"
      class="form-control addtxt"> <svg [attr.disabled]="comment === ''" (click)= "sendComment(comment)" xmlns="http://www.w3.org/2000/svg" width="30" height="30" color = "green"fill="currentColor"
      class="bi bi-cursor" id= "sendIcon" viewBox="0 0 16 16">
      <path
        d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52 2.25 8.184z" />
    </svg></div>
  
    <div *ngIf = "noComments == false"> 
      <div style="height:200px; overflow-y:auto;" #scroll>
  <div *ngFor = "let comment of comments | async">
  <div class="d-flex justify-content-center py-2">
    <div class="second py-2 px-2"> <span class="text1">{{comment.comment_content}}</span>
      <div class="d-flex justify-content-between py-1 pt-2">
        <div><img src="https://i.imgur.com/AgAC1Is.jpg" width="18"><span class="text2">{{comment.comment_creator}}</span></div>
        <div><span class="text3">Upvote?</span><span class="thumbup"><i class="fa fa-thumbs-o-up"></i></span><span
            class="text4">3</span></div>
      </div>
    </div>
  </div>
  <div *ngIf = "noComments == true">
    <h1> No comments have been left yet for this task 
      
    </h1>
  </div>
</div>
</div>

</div>
</div>
